// TODO: Clean up and integrate with lms-components

import {
    ComponentType,
    ReactNode,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import dynamic from 'next/dynamic';
import { ChatTrigger } from '@lg-chat/fixed-chat-window';
import { SUGGESTED_PROMPTS } from '../../data/chatbot';
import { useChatbotContext as useAppChatbotContext } from '../../contexts/chatbot';

// const CHATBOT_BASE_URL =
//     process.env['APP_ENV'] === 'production'
//         ? 'https://knowledge.mongodb.com/api/v1'
//         : 'https://knowledge.staging.corp.mongodb.com/api/v1';

// TODO: Fix env check
const CHATBOT_BASE_URL = 'https://knowledge.mongodb.com/api/v1';

const ChatbotProvider = dynamic(() => import('mongodb-chatbot-ui'), {
    ssr: false,
});
const ActionButtonTrigger = dynamic(
    () => import('mongodb-chatbot-ui').then(mod => mod.ActionButtonTrigger),
    { ssr: false }
);
const ModalView = dynamic(
    () => import('mongodb-chatbot-ui').then(mod => mod.ModalView),
    { ssr: false }
);
const MongoDbLegalDisclosure = dynamic(
    () => import('mongodb-chatbot-ui').then(mod => mod.MongoDbLegalDisclosure),
    { ssr: false }
);
const HotkeyTrigger = dynamic(
    () => import('mongodb-chatbot-ui').then(mod => mod.HotkeyTrigger),
    { ssr: false }
);

const PoweredByAtlasVectorSearch = dynamic(
    () =>
        import('mongodb-chatbot-ui').then(
            mod => mod.PoweredByAtlasVectorSearch
        ),
    { ssr: false }
);

interface SuspenseHelperProps {
    fallback: NonNullable<ReactNode> | null;
    children?: ReactNode;
}

export const SuspenseHelper = ({ fallback, children }: SuspenseHelperProps) => {
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
        if (!isMounted) {
            setMounted(true);
        }
    }, [isMounted]);

    return (
        <Suspense fallback={fallback}>
            {' '}
            {!isMounted ? fallback : children}
        </Suspense>
    );
};

type ModuleImport<T> = () => Promise<T>;
type Extractor<T, U> = (module: T) => U;

export const withLazy = <T extends object, U extends object>(
    Component: ComponentType<U & T>,
    moduleImport: ModuleImport<T>,
    extractor: Extractor<T, U>
): any => {
    const WithLazyComponent = (props: T) => {
        const lazyModuleRef = useRef<U | null>(null);
        const [isLoaded, setIsLoaded] = useState(false);

        useEffect(() => {
            moduleImport().then(module => {
                lazyModuleRef.current = extractor(module);
                setIsLoaded(true);
                return module;
            });
        }, []);

        return isLoaded ? (
            // @ts-expect-error err
            <Component {...lazyModuleRef.current} {...props} />
        ) : null;
    };

    WithLazyComponent.displayName = 'WithLazyComponent';

    return WithLazyComponent;
};

const ChatbotContent = withLazy(
    ({
        useChatbotContext,
        inputBottomText,
        suggestedPrompts,
        shouldOpen,
        onLoad,
        ...rest
    }: any) => {
        const { openChat, open } = useChatbotContext();

        useEffect(() => {
            if (shouldOpen && !open) {
                openChat();
            }
        }, [shouldOpen, open, openChat]);

        useEffect(() => {
            onLoad();
            window.openMongoDBChatbotWindow = openChat;
        }, []);

        return (
            <div
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: 48,
                    '> button': {
                        height: '100%',
                        p: {
                            py: 0,
                            whiteSpace: 'nowrap',
                            display: ['none', null, null, null, 'block'],
                        },
                    },
                }}
            >
                <ActionButtonTrigger text="MongoDB AI" />
                <HotkeyTrigger onKey="/" />
                <div data-testid="chatbot-wrapper">
                    <ModalView
                        initialMessageText="Hi! I'm the MongoDB AI. What can I help you with today?"
                        inputBottomText={inputBottomText}
                        disclaimer={
                            <>
                                <MongoDbLegalDisclosure />
                                <PoweredByAtlasVectorSearch linkStyle="text" />
                            </>
                        }
                        sx={{
                            textarea: {
                                boxShadow: 'none',
                                minHeight: 'auto !important',
                            },
                            zIndex: '10001 !important',
                        }}
                        data-testid="chatbot-modal"
                        initialMessageSuggestedPrompts={suggestedPrompts}
                        shouldUseSlashHotkey
                        {...rest}
                    />
                </div>
            </div>
        );
    },
    () => import('mongodb-chatbot-ui'),
    module => ({ useChatbotContext: module.useChatbotContext })
);

const DevCenterChatbot = () => {
    const {
        chatbotInitialOpen,
        setChatbotInitialOpen,
        chatbotWindowLoaded,
        setChatbotWindowLoaded,
    } = useAppChatbotContext();

    return (
        <div
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 'inc20',
            }}
            className="devcenter-chatbot"
        >
            <div
                sx={{
                    position: 'relative',
                    height: 48,
                    width: [48, null, null, null, 136],
                }}
            >
                {!chatbotWindowLoaded && (
                    <div
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: 48,
                        }}
                        onClick={() => setChatbotInitialOpen(true)}
                    >
                        <ChatTrigger
                            sx={{
                                height: '100%',
                                '> div, p': { py: 0, whiteSpace: 'nowrap' },
                                p: {
                                    display: [
                                        'none',
                                        null,
                                        null,
                                        null,
                                        'block',
                                    ],
                                },
                            }}
                            onClick={() => setChatbotInitialOpen(true)}
                        >
                            MongoDB AI
                        </ChatTrigger>
                    </div>
                )}

                {chatbotInitialOpen && (
                    <ChatbotProvider serverBaseUrl={CHATBOT_BASE_URL}>
                        <ChatbotContent
                            suggestedPrompts={SUGGESTED_PROMPTS}
                            inputBottomText="This is an experimental generative AI chatbot. All information should be verified prior to use."
                            shouldOpen={
                                chatbotInitialOpen && !chatbotWindowLoaded
                            }
                            onLoad={() => setChatbotWindowLoaded(true)}
                        />
                    </ChatbotProvider>
                )}
            </div>
        </div>
    );
};

export default DevCenterChatbot;
