import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

export const ChatbotContext = createContext<{
    chatbotInitialOpen: boolean;
    setChatbotInitialOpen: (open: boolean) => void;
    chatbotWindowLoaded: boolean;
    setChatbotWindowLoaded: (loaded: boolean) => void;
}>({
    chatbotInitialOpen: false,
    setChatbotInitialOpen: () => null,
    chatbotWindowLoaded: false,
    setChatbotWindowLoaded: () => null,
});

export interface UseEventOptions {
    options?: Omit<AddEventListenerOptions, 'once'>;
    dependencies?: Array<any>;
    enabled?: boolean | 'once';
    element?: Document | HTMLElement;
}

export default function useEventListener<Type extends keyof DocumentEventMap>(
    type: Type,
    eventCallback: (e: DocumentEventMap[Type]) => void,
    {
        options,
        enabled = true,
        dependencies = [enabled, type],
        element,
    }: UseEventOptions = {}
) {
    const memoizedEventCallback: React.MutableRefObject<
        (e: DocumentEventMap[Type]) => void
    > = useRef(() => {});

    useEffect(() => {
        memoizedEventCallback.current = eventCallback;
    }, [eventCallback]);

    useEffect(() => {
        if (enabled === false) {
            return;
        }

        // Handle this in case non-TypeScript users pass in the wrong value
        if (enabled !== 'once' && enabled !== true) {
            console.error(
                `Received value of type ${typeof enabled} for property \`enabled\`. Expected a boolean.`
            );
            return;
        }

        const callback = (e: DocumentEventMap[Type]) => {
            memoizedEventCallback.current(e);
        };

        const eventListenerOptions = {
            ...options,
            once: enabled === 'once',
        };

        // NOTE(JeT): I'm pretty sure there should be a way to avoid this type assertion, but I couldn't figure it out.
        (element ?? document).addEventListener(
            type,
            callback as EventListener,
            eventListenerOptions
        );

        return () => {
            (element ?? document).removeEventListener(
                type,
                callback as EventListener,
                eventListenerOptions
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}

export const ChatbotProvider = ({ children }: { children: ReactNode }) => {
    const [chatbotInitialOpen, setChatbotInitialOpen] = useState(false);
    const [chatbotWindowLoaded, setChatbotWindowLoaded] = useState(false);

    useEventListener(
        'keydown',
        (e: KeyboardEvent) => {
            if (!e.repeat && e.key === '/') {
                e.preventDefault();
                e.stopPropagation();
                setChatbotInitialOpen(true);
            }
        },
        {
            enabled: !chatbotInitialOpen,
        }
    );

    return (
        <ChatbotContext.Provider
            value={{
                chatbotInitialOpen,
                setChatbotInitialOpen,
                chatbotWindowLoaded,
                setChatbotWindowLoaded,
            }}
        >
            {children}
        </ChatbotContext.Provider>
    );
};

export const useChatbotContext = () => useContext(ChatbotContext);
