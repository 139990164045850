import Head from 'next/head';
import getConfig from 'next/config';
import { Session } from 'next-auth';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import theme from '@mdb/flora/theme';
import { ThemeProvider } from '@theme-ui/core';
import { CacheProvider } from '@emotion/react';

import { OverlayProvider } from '../contexts/overlay';
import { ModalProvider } from '../contexts/modal';
import { NotificationsProvider } from '../contexts/notification';

import ModalRoot from '../components/modal';
import { NotificationsContainer } from '../components/notification';
import Layout from '../components/layout';
import ErrorBoundary from '../components/error-boundary';
import {
    getMetaDescr,
    getCanonicalUrl,
    shouldDefineDefaultCanonical,
} from '../utils/seo';
import { customCache } from '../utils/emotion';

import '../../mocks/run-msw';
import { AVAILABLE_LANGUAGES } from '../utils/locale';
import { ChatbotProvider } from '../contexts/chatbot';

interface CustomProps {
    session?: Session;
}

function MyApp({ Component, pageProps, session }: AppProps & CustomProps) {
    const router = useRouter();
    const { hideMenu } = router.query;
    // PathFactory embeds content pages, and would like certain elements to be removed via query param.
    const isPathFactory = hideMenu === '1';
    const { publicRuntimeConfig } = getConfig();
    const { absoluteBasePath } = publicRuntimeConfig;
    const { asPath, route } = router;

    const pagePath = route === '/_error' ? null : asPath;
    // Attributes for SEO may be overridden at the component-level if needed with NextSeo.
    const pageDescription = getMetaDescr(publicRuntimeConfig, route, asPath);
    const canonicalUrl = shouldDefineDefaultCanonical(route)
        ? getCanonicalUrl(absoluteBasePath, asPath)
        : null;

    /* as path returned by GDN seems to be same for localized as well as english sites
       but for local development as path seems to be different.
       eg: as path for http://localhost:3000/pt-br/developer/languages/java/testcontainers-with-java-and-mongodb/ comes as
       "pt-br/developer/languages/java/testcontainers-with-java-and-mongodb/"
       where are GDN just returns "languages/java/testcontainers-with-java-and-mongodb/"
       The function removes any <localized-code>/developer from as path.
       Though this changed is not needed for production there is no harm of having this logic
    */
    const normalizeLocalizedAsPath = (asPath: string) => {
        const langPattern = /^\/[^/]+\/developer/;
        return langPattern.test(asPath)
            ? asPath.replace(langPattern, '')
            : asPath;
    };

    /* absolute path returned by GDN for localized sites - https://www.mongodb.com/<localized-code>/developer
       absolute path returned by GDN for english sites - https://www.mongodb.com/developer
       1. this function should rip of localized-code if any
       2. then if langCode is en-us you do nothing
       3. then if langCode is anything else we want to add langcode to that absolute path
    */
    const normalizeLocalizedAbsolutePath = (
        absolutePath: string,
        langCode: string
    ) => {
        // Use regex to match and remove localized code if present
        const basePath = absolutePath.replace(
            /(https?:\/\/[^/]+)(\/[^/]+)?\/developer/,
            '$1' + '/'
        );

        // Append '/developer' if langCode is not 'en-us'
        return langCode === 'en-us'
            ? basePath + 'developer'
            : (basePath + langCode + '-' + 'developer').replace(
                  langCode + '-',
                  langCode + '/'
              );
    };

    return (
        <>
            <Head>
                <title>MongoDB Developer Center</title>
                {pageDescription && (
                    <meta name="description" content={pageDescription} />
                )}
                <link rel="icon" href="/developer/favicon.ico" />
                {canonicalUrl && (
                    <link rel="canonical" href={`${canonicalUrl}`} />
                )}
                {AVAILABLE_LANGUAGES.map(lang => (
                    <link
                        className="sl_opaque"
                        key={lang.code}
                        rel="alternate"
                        hrefLang={
                            lang.code === 'en-us' ? 'x-default' : lang.code
                        }
                        href={getCanonicalUrl(
                            normalizeLocalizedAbsolutePath(
                                absoluteBasePath,
                                lang.code
                            ),
                            normalizeLocalizedAsPath(asPath)
                        )}
                    />
                ))}
                <meta
                    name="google-site-verification"
                    content="arHWtTB1TZeJM1p8IWZyn0QOfHnkaMK47hdnQIief9E"
                />
            </Head>
            <SessionProvider
                session={session}
                basePath="/developer/api/auth/"
                refetchOnWindowFocus={true}
                refetchInterval={0}
            >
                <CacheProvider value={customCache(theme)}>
                    <ThemeProvider theme={theme}>
                        <NotificationsProvider>
                            <ModalProvider>
                                <OverlayProvider>
                                    <ChatbotProvider>
                                        <Layout
                                            pagePath={pagePath}
                                            isPathFactory={isPathFactory}
                                        >
                                            <ErrorBoundary>
                                                <ModalRoot />
                                                <NotificationsContainer />
                                                <Component {...pageProps} />
                                            </ErrorBoundary>
                                        </Layout>
                                    </ChatbotProvider>
                                </OverlayProvider>
                            </ModalProvider>
                        </NotificationsProvider>
                    </ThemeProvider>
                </CacheProvider>
            </SessionProvider>
        </>
    );
}

export default MyApp;
