import { useContext, useEffect, useMemo, useState } from 'react';
import { Global, css } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';
import { useSession } from 'next-auth/react';
import { UnifiedNav } from '@mdb/consistent-nav';
import { globalStyles, Main } from '../styled/layout';
import { ThemeUICSSObject } from 'theme-ui';
import SecondaryNav from './secondary-nav';
import { OverlayContext } from '../contexts/overlay';
import { layers } from '../styled/layout';
import { useEnsureImageAlts } from '../utils/seo';
import { useModalContext } from '../contexts/modal';
import { PaginatedPersonalizationModal } from './modal/personalization';
import getSignInURL from '../utils/get-sign-in-url';
import useUserPreferences from '../hooks/personalization/user-preferences';
import { Globals as FloraGlobals } from '@mdb/flora';
import Footer from './footer';
import { enabledLocales, getCurrLocale, onSelectLocale } from '../utils/locale';
import { SiteBanner } from '@mdb/lms-components';
import { SiteBannerType } from '../types/site-banner-type';
import allMongoDBTVShows from '../service/get-all-mongodb-tv-shows.preval';
import { useChatbotContext } from '../contexts/chatbot';

const navStyles = (sticky: boolean): ThemeUICSSObject => ({
    zIndex: '9999',
    position: sticky ? 'sticky' : 'relative',
    top: sticky ? 0 : 'auto',
    'nav > div > div > ul': {
        zIndex: `${layers.desktopConsistentNavDropdown}!important`,
    }, // Needed so <ul /> list options from consistent nav displays over our secondary nav
    'div[role=menu-wrapper]': {
        zIndex: `${layers.mobileNavMenu}!important`,
    },
});
interface LayoutProps {
    pagePath?: string | null;
    isPathFactory?: boolean;
}

const getBanners = (setChatbotInitialOpen: (open: boolean) => void) => {
    const currentTime = new Date();
    const banners: SiteBannerType[] = [
        {
            text: (
                <p>
                    <strong>
                        Explore Developer Center&apos;s New Chatbot!
                    </strong>{' '}
                    MongoDB AI Chatbot can be accessed at the top of your
                    navigation to answer all your MongoDB questions.
                </p>
            ),
            buttonText: 'VIEW MONGO AI',
            buttonOnClick: () => {
                if (window.openMongoDBChatbotWindow) {
                    window.openMongoDBChatbotWindow();
                } else {
                    setChatbotInitialOpen(true);
                }
            },
        },
    ];
    if (
        currentTime > new Date('2024-10-02T06:00:00.000-04:00') &&
        currentTime < new Date('2024-10-18T23:59:00.000-04:00')
    ) {
        banners.push({
            text: 'Introducing MongoDB 8.0, the fastest MongoDB ever!',
            buttonText: 'READ MORE',
            buttonOnClick: () =>
                (window.location.href =
                    'https://www.mongodb.com/blog/post/top-4-reasons-to-use-mongodb-8-0?tck=mdb80_blog_pencil_banner'),
        });
    }

    if (currentTime < new Date('2024-12-05T23:59:00.000-04:00')) {
        banners.push({
            text: 'Join us at AWS re:Invent 2024! Learn how to use MongoDB for AI use cases.',
            buttonText: 'Learn More >',
            buttonOnClick: () =>
                (window.location.href =
                    'https://www.mongodb.com/events/aws-reinvent?tck=developer_pencil_banner'),
        });
    }

    const liveVideo = allMongoDBTVShows.find(({ contentDate }) => {
        if (!Array.isArray(contentDate) || contentDate.length < 2) {
            return false;
        }
        const startTime = new Date(contentDate[0]);
        const endTime = new Date(contentDate[1]);

        if (startTime <= currentTime && endTime >= currentTime) {
            return true;
        }
    });

    if (liveVideo) {
        const { videoId, title } = liveVideo;
        if (videoId) {
            banners.push({
                text: title,
                buttonText: 'Watch Now',
                buttonOnClick: () =>
                    (window.location.href = `https://www.youtube.com/watch?v=${videoId}`),
            });
        }
    }

    return banners;
};

const Banner = ({ banners }: { banners: SiteBannerType[] }) => (
    <SiteBanner
        styles={{ svg: { width: 'auto' } }}
        content={banners.length === 1 ? banners[0] : banners}
    />
);

const Layout: React.FunctionComponent<LayoutProps> = ({
    children,
    pagePath,
    isPathFactory,
}) => {
    const { updateUserPreferences } = useUserPreferences();
    const { hasOverlay } = useContext(OverlayContext);
    const { component: hasModalOpen, openModal } = useModalContext();
    const { data: session } = useSession();
    const { setChatbotInitialOpen } = useChatbotContext();

    const banners = useMemo(
        () => getBanners(setChatbotInitialOpen),
        [setChatbotInitialOpen]
    );

    useEffect(() => {
        if (session && !session.lastLogin && !session.failedToFetch) {
            // Don't spam with the modal if we failed to fetch.
            openModal(
                <PaginatedPersonalizationModal />,
                // Pass default values to PUT if user dismisses the modal so their "lastLogin" flag can be updated
                {
                    onCloseCallback: () =>
                        updateUserPreferences({
                            followedTags: [],
                            emailPreference: false,
                        }),
                }
            );
        }
    }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

    const signInUrl = getSignInURL(pagePath);

    const isBooksPage = pagePath?.startsWith('/books/') || false;

    // SEO workaround because unified nav/footer doesn't set their image alts properly
    const [layoutRef, setLayoutRef] = useState<HTMLElement | null>();
    useEnsureImageAlts(layoutRef);

    return (
        <div ref={ref => setLayoutRef(ref)}>
            <Global
                // TODO: using globalStyles as func call might be producing performance bottleneck
                styles={css`
                    ${emotionNormalize}
                    ${globalStyles(!!hasOverlay || !!hasModalOpen)}
                `}
            />
            <FloraGlobals />
            {!isPathFactory && (
                <>
                    {!!banners && !isBooksPage && <Banner banners={banners} />}
                    <div sx={navStyles(isBooksPage)}>
                        <UnifiedNav
                            position="static"
                            floraTheme="default"
                            property={{ name: 'DEVHUB', searchParams: [] }}
                            hideTryFree={!!session}
                            hideSignIn={!!session}
                            signInUrl={signInUrl}
                            showLanguageSelector={true}
                            onSelectLocale={onSelectLocale}
                            locale={getCurrLocale()}
                            enabledLocales={enabledLocales}
                            darkMode={isBooksPage}
                        />
                    </div>
                    {!isBooksPage && <SecondaryNav />}
                </>
            )}

            <Main>{children}</Main>
            {!isPathFactory && !isBooksPage && <Footer />}
        </div>
    );
};

export default Layout;
